export const it = {
  browserNotSupported: "Spiacente, il tuo browser non è supportato. Aggiornalo o utilizzane un altro per poter completare l'operazione.",
  invalidUrl: "Spiacente, questo URL non è valido.",
  button: {
    next: "Avanti",
    back: "Indietro",
    confirm: "Conferma",
    addCard: "Aggiungi nuovo metodo di pagamento",
    remove: "Elimina",
    cancel:  "Annulla",
    refresh: "Aggiorna",
  },
  plan: {
      frequency: "Frequenza",
      planSchedule: "piano scadenze",
      planRecurrent: "piano ricorrente",
      planOneTime: "piano di addebito",
      every: "Ogni",
      till: "fino al",
      for: "per",
      date: "Data",
      startDate: "Data Inizio",
      period: {
        day: "giorno",
        day_plural: "giorni",
        month: "mese",
        month_plural: "mesi",
        year: "anno",
        year_plural: "anni",
      }
  },
  orderDetails: {
      amount: "Importo",
      imageUrl: "Logo",
      title: "Titolo",
      desc: "Descrizione",
      paymentRef: "Causale",
      validityEndDate: "Data fine validità",
      contextId: "ContextID",
      maxAmount: "Importo massimo autorizzato",
      minAmount: "Importo minimo autorizzato",
      companyNamePrefix: "TeamSystem Payments S.r.l. per conto di ",
      charge: "addebito",
      charge_plural: "addebiti"
  },
  step: {
    paymentDetails: {
        title: "PAGA",
        subtitle: "Registra una sorgente di pagamento per l'abbonamento annuale alla palestra Athlon",
        card: {
            number: "Numero carta:",
            expiration: "Scadenza:",
            cvv: "CVV:"
        },
        selectPaymentMethod: "Vuoi utilizzare un metodo di pagamento salvato in precedenza?",
        registerCard: "Vuoi utilizzare un metodo di pagamento salvato in precedenza?",
        amount: "Costo",
        expiration: "*Questo link è valido sino al ",
        enterDate: "Inserisci una data"
    },
      paymentConfirmation: {
        title: "Riepilogo Operazione",
        card: "Carta di Credito / Debito",
        sepa_debit: "Conto Corrente",
      },
    customerIdentification: {
      title: "INSERISCI I TUOI DATI",
      info: "Inserisci il tuo indirizzo e-mail o il tuo numero di cellulare per ricevere il codice di verifica",
      enterEmail: "Inserisci l'email",
      enterYourEmail: "Inserisci la tua e-mail",
      enterEmailOrMobileNumber: "Inserisci e-mail o cellulare",
      email: "E-mail",
      mobileNumber: "Cellulare",
      wrongCredentials: "Credenziali non valide",
      privacyAndTosDisclaimer: "Accetto le <0>Condizioni del servizio</0> e dichiaro di aver preso visione dell'<1>Informativa privacy</1>"
    },
    customerVerification: {
      title: {
          line1: "INSERISCI IL CODICE CHE",
          line2: "HAI RICEVUTO",
          line2email: "HAI RICEVUTO VIA E-MAIL",
          line2mobile: "HAI RICEVUTO VIA CELLULARE"
      },
      otpCode: "Codice OTP",
      noOtpCodeReceivedInfo: "Non hai ricevuto il codice? Richiedi un nuovo invio",
      enterOtpCode: "Inserisci il codice OTP",
      otpSent: "OTP Inviato",
    },
      paymentMethodSave: {
          title: "Autorizza",
      },
    paymentMethodList: {
      title: "Seleziona metodo di pagamento",
      card: "CARTA DI CREDITO/DEBITO",
      account: "CONTO CORRENTE",
      expiry: "Scadenza",
        modal: {
            info: "Conferma"
        },
        onItem: 'Nessun metodo di pagamento trovato.'
    },
    paymentMethodAdd: {
      title: "INSERISCI I DATI DELLA CARTA DA ASSOCIARE A QUESTA TIPOLOGIA DI PAGAMENTI",
      cardNumber: "Numero della carta:",
      expiration: "Data di scadenza (MM/AA):",
      cvv: "CVV:"
    },
      thankYou: {
          title: 'GRAZIE!',
          subtitle: 'PAGAMENTO conclusO con successo!',
          subtitleLink2save: "Autorizzazione Conclusa con Successo!",
          orderDetailsLabel: 'RIEPILOGO OPERAZIONE',
          paymentMethodDetailsLabel: 'Carta di Credito / Debito',
          contact: {
              label:'Hai bisogno di informazioni?',
              link: 'Contattaci'
          },
          infoMessage:"L'addebito sarà visibile nel tuo estratto conto come «TeamSystem Pay»",
          print:'SCARICA',
          newPayment:'NUOVO PAGAMENTO',
          transactionCode:'NUMERO OPERAZIONE: '
      },
      tspayDetail: {
        info: "Non conosci TS Pay?",
        urlInfo: "Clicca qui per maggiori informazioni"
      },
      netAmountChargePdf: {
        info: '*Incluso costo operazione di',
        url: '(Termini e condizioni)'
      }
  },
    paymentMethod: {
        email: "Email",
        name: "Nome",
        card: "Carta",
        cardDesc: "Carta di credito o debito",
        sepaDebit: "IBAN",
        sepaDebitDesc: "Addebito diretto in conto",
        pisDesc: "Bonifico istantaneo",
        sepaDebitTitle: "Il tuo IBAN",
        saveCard: "Salva la carta per pagamenti futuri (opzionale)",
        saveSepaDebit: "Salva l’IBAN per pagamenti futuri (opzionale)",
        cardNumber: "Numero della carta",
        cardExpiry: "Scadenza",
        cardCvc: "CVV",
        selectPayMethod: "Seleziona un metodo di pagamento",
        // declaimer: "By providing your IBAN and confirming this payment, you are authorizing Rocketship Inc. and Stripe, our payment service provider, to send instructions to your bank to debit your account and your bank to debit your account in accordance with those instructions. You are entitled to a refund from your bank under the terms and conditions of your agreement with your bank. A refund must be claimed within 8 weeks starting from the date on which your account was debited."
        Link2Save: {
            declaimer: "Inserendo il tuo codice IBAN e confermando questa operazione stai conferendo il mandato ed autorizzando TeamSystem Payments S.r.l. ad inviare istruzioni alla tua banca per l’addebito dell’importo concordato e stai autorizzando la tua banca ad addebitare sul tuo conto il suddetto importo conformemente alle istruzioni impartite da TeamSystem Payments S.r.l.. Puoi richiedere il rimborso dell’importo alla tua banca secondo i termini e le condizioni sottoscritti con quest’ultima. La richiesta di rimborso deve essere inoltrata alla tua banca entro 8 otto settimane dalla data di addebito sul conto.",
            declaimerBottom: 'TeamSystem Pay utilizza sistemi avanzati di antifrode per garantire la sicurezza delle operazioni. I dati devono essere inseriti dal titolare del conto o da personale autorizzato. Ogni abuso sarà perseguito a norma di legge.'
        },
        Link2Pay: {
            declaimer: "Inserendo il tuo codice IBAN e confermando questa operazione stai conferendo il mandato ed autorizzando TeamSystem Payments S.r.l. ad inviare istruzioni alla tua banca per l’addebito dell’importo concordato e stai autorizzando la tua banca ad addebitare sul tuo conto il suddetto importo conformemente alle istruzioni impartite da TeamSystem Payments S.r.l.. Puoi richiedere il rimborso dell’importo alla tua banca secondo i termini e le condizioni sottoscritti con quest’ultima. La richiesta di rimborso deve essere inoltrata alla tua banca entro 8 otto settimane dalla data di addebito sul conto.",
            declaimerBottom: 'TeamSystem Pay utilizza sistemi avanzati di antifrode per garantire la sicurezza delle operazioni. I dati devono essere inseriti dal titolare del conto o da personale autorizzato. Ogni abuso sarà perseguito a norma di legge.'
        }
    },
    chargePaymentRefInput : {
      placeholder: "Inserisci il motivo del pagamento"
    },
    customerNameInput : {
        label: "Nome associato al conto",
        placeholder: "Inserisci l'intestatario del conto corrente"
    },
    customerAddressInput : {
      label: "Indirizzo",
      placeholder: "Inserisci l'indirizzo"
    },
    tabs: {
        card: "CARTA DI CREDITO",
        sepa_debit: "IBAN"
    },
    accordion: {
        card: "CARTA DI CREDITO / DEBITO",
        sepa_debit: "IBAN (ADDEBITO DIRETTO)"
    },
    thankYou: {
        info: "Grazie"
    },
    removeModal: {
      header: 'CONFERMA ELIMINAZIONE',
      info: "Sei sicuro di voler eliminare il metodo di pagamento dal wallet?"
    },
    loading: "Attendi, stai per essere reindirizzato. Non chiudere questa pagina.",
    tooltip: {
        saveCard: {
            header: "Come funziona?",
            content: "Selezionando questa opzione scegli di memorizzare il metodo di pagamento indicato, in modo da poterlo riutilizzare in maniera rapida e sicura nelle future operazioni con TeamSystem Pay."
        },
        saveSepaDebit: {
            header: "Come funziona?",
            content: "Selezionando questa opzione scegli di memorizzare il metodo di pagamento indicato, in modo da poterlo riutilizzare in maniera rapida e sicura nelle future operazioni con TeamSystem Pay."
        },
        customerVerification: {
            header: "OTP",
            content: "OTP (One Time Password) indica una password “usa e getta” inviata via email o SMS che viene generata al momento ed è utilizzabile una sola volta.\n\nL’inserimento del codice OTP è necessario per autorizzare l’operazione richiesta in maniera sicura."
        },
        dateInput: {
            header: "Data fine autorizzazione",
            content: "Puoi decidere fino a quando l'autorizzazione sarà valida. Se non lo fai, non è prevista una scadenza",
        },
        paymentSchedulePlan: {
          header: "Data originale di pagamento:"
        }
    },
    error: {
        unknown: "Spiacente, si è verificato un errore. Ti preghiamo di riprovare.",
        noOrderKey: "Ordine non trovato",
        noCallbackUrl: "Spiacente, si è verificato un errore durante il redirect. Ti preghiamo di riprovare.",
        invalidOtpCode: "Il codice OTP inserito non è corretto",
        invalidSepaDebit: "Il tuo IBAN non è supportato",
        invalidCard: "La tua carta non è supportata",
        invalidBrand: "Il circuito di pagamento di questa carta non è supportato",
        invalidFundingType: "La tipologia di carta non è supportata",
        invalidMobileNo: "Il numero di telefono inserito non è corretto",
        orderExpired: "Questo ordine è scaduto"
    }
};